<template>
	<div id="fileUpdataReview" v-loading="loading" :element-loading-text="loadingText">
		<div class="top">
			<div class="title">{{project.folderName}}<i></i></div>
			<div class="content">
				<p>共 {{total}} 项</p>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" width="500" trigger="hover">
					<div class="popoverCont">
						<p><b>视频文件解析问题：</b>为防止视频文件自身问题或传输过程中的编码问题 造成视频文件解析异常，视频文件 请先压缩 以压缩文件格式上传</p>
						<p><b>文件上传成功率：</b>为确保文件上传成功率 以及减少传输时长 建议单个文件大小控制在400M以内</p>
						<p><b>文档与图片预览速度：</b>为确保文档与图片的预览速度 建议文档与图片单个文件大小控制在20M以内</p>
					</div>
					<p slot="reference">上传提示:<i class="el-icon-s-opportunity"></i></p>
				</el-popover>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" width="500" trigger="hover">
					<p slot="reference">文件上传要求:<i class="el-icon-question"></i></p>
					<div class="popoverCont">
						<p>
							<b>{{project.folderName}}：</b>
							{{project.ifFileRequired=="Y"?"文件必传":"文件非必传"}}
							{{project.fileUploadRequirement}}
						</p>
					</div>
				</el-popover>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" width="500" trigger="hover">
					<p slot="reference">图片上传要求:<i class="el-icon-question"></i></p>
					<div class="popoverCont">
						<p v-if="project.maxFileNum">
							<b>{{project.folderName}}：</b> 图片张数限制
							{{project.minFileNum}} - {{project.maxFileNum}}
						</p>
						<p v-else>
							<b>{{project.folderName}}：</b> 图片张数限制
							{{project.minFileNum}} - {{project.maxFileNum}}
						</p>
					</div>
				</el-popover>
				<el-popover class="markers" popper-class="markers" placement="bottom-start" trigger="hover">
					<p slot="reference">文件审核状态(全部文件):<i class="el-icon-info"></i></p>
					<div class="popoverCont">
						<p><b>未上传文件</b></p>
					</div>
				</el-popover>
			</div>
			<div class="tableBotions">
				<!-- <el-button type="text" @click="bulkDownload">批量下载</el-button> -->
				<el-link type="primary" @click="batchDeletion">批量删除</el-link>
			</div>
		</div>
		<div class="option">
			<el-upload :action="`${baseUrl}/sign/live/doctor/uploadFile`" :show-file-list="false" multiple
				:headers="{token:token}" :on-success="uploadOnSuccess" :before-upload="beforeUpload">
				<el-button size="small" type="primary" plain icon="el-icon-upload">上传</el-button>
			</el-upload>
			<el-button size="small" type="primary" plain icon="el-icon-circle-plus"
				@click="newFolder()">新建文件夹</el-button>
			<div class="right">
				<el-pagination :disabled="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size"
					layout="total, sizes, prev, pager, next" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-table :data="fileList" style="width: 100%" @selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55" align="center"></el-table-column>
			<el-table-column align="left" label="名称" min-width="150">
				<template slot-scope="scope">
					<span @click="tableCellClick(scope.row)">
						<img v-if="scope.row.type == 'FOLDER'" src="../../../assets/imgs/i_file.svg" alt="">
						<img v-if="fileType(scope.row.name) == 'image'" src="../../../assets/imgs/i_img.png" alt="">
						<img v-if="fileType(scope.row.name) == 'xls'" src="../../../assets/imgs/xls.png" alt="">
						<img v-if="fileType(scope.row.name) == 'doc'" src="../../../assets/imgs/doc.png" alt="">
						<img v-if="fileType(scope.row.name) == 'pdf'" src="../../../assets/imgs/pdf.png" alt="">
						<img v-if="fileType(scope.row.name) == 'ppt'" src="../../../assets/imgs/ppt.png" alt="">
						{{scope.row.name}}
					</span>
				</template>
			</el-table-column>
			<el-table-column prop="2" align="center" label="费用" width="200">
				<template slot-scope="scope">
					<el-input type="number" v-model="scope.row.applyMoney" size="mini" v-if="scope.row.isMoney == '1'"
						@input="applyMoneyChange(scope.row)">
						<template slot="append">元</template>
					</el-input>
				</template>
			</el-table-column>
			<el-table-column prop="2" align="center" label="文件审核状态">
				<template slot-scope="scope">
					<el-tag size="mini" type="info" v-if="scope.row.auditStatus == 0">待审核</el-tag>
					<el-tag size="mini" type="success" v-if="scope.row.auditStatus == 1">审核成功</el-tag>
					<el-tag size="mini" type="danger" v-if="scope.row.auditStatus == 2">审核失败</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="examineMessage" label="驳回原因"> </el-table-column>
			<el-table-column align="center" label="审核时间">
				<template slot-scope="scope">
					<span v-if="scope.row.examineTime">
						{{ $moment(scope.row.examineTime).format('l') }}
						<!--{{ $moment(scope.row.examineTime).format('LTS') }}-->
					</span>
					<span v-else> - </span>
				</template>
			</el-table-column>
			<el-table-column label="文件数量"> </el-table-column>
			<el-table-column align="center" label="修改时间">
				<template slot-scope="scope">
					{{ $moment(scope.row.updateTime).format('l') }}
					{{ $moment(scope.row.updateTime).format('LTS') }}
				</template>
			</el-table-column>
			<el-table-column prop="fileSize" width="100" align="center" label="文件大小">
				<template slot-scope="scope">
					{{ scope.row.fileSize? `${(scope.row.fileSize/1024/1024).toFixed(2) }MB` : 0}}
				</template>
			</el-table-column>
			<el-table-column prop="3" width="200" align="center" label="操作">
				<template slot-scope="scope">
					<el-button v-if="scope.row.type == 'FOLDER'" type="text"
						@click="UpTarget(scope.row)">去上传</el-button>
					<el-button type="text" @click="downloadFileFun(scope.row)">下载</el-button>
					<el-button type="text" @click="deleteFile(scope.row)">删除</el-button>
					<el-button v-if="scope.row.type == 'FOLDER'" type="text"
						@click="newFolder(scope.row.id)">编辑</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination :disabled="loading" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="current" :page-sizes="[10, 20, 50, 100]" :page-size="size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" background>
		</el-pagination>
		<el-dialog title="文件预览" v-if="fileShow" :visible.sync="fileShow" width="900px" custom-class="filePreview">
			<el-image :src="fileUrl" v-if="fileType == 'image'"></el-image>
			<div class="pdfFile" v-if="fileType == 'pdf'">
				<pdf :src="fileUrl" :page="currentPage" @num-pages="handleNumPages"></pdf>
				<p class="pages" v-if="numPages !== 0">总页数: {{ numPages }}</p>
				<div class="opts">
					<el-button @click="previousPages" :disabled="currentPage === 1">上一页</el-button>
					<el-button @click="nextPages" :disabled="currentPage === numPages">下一页</el-button>
				</div>
			</div>
			<div class="otherFile" v-if="fileType == 'file'">
				<iframe :src="`https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`"></iframe>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import baseUrl from '@/request/config'
	import {
		getFolderById,
		submitFiles,
		getFiles,
		saveOrUpdateFolder,
		delFolderAndFiles,
		downloadFile,
		editFolderMoney
	} from '@/request/api'
	import pdf from "vue-pdf";
	export default {
		name: 'fileUpdataReview',
		data() {
			return {
				baseUrl,
				id: '',
				project: '',
				fileRow: '',
				multipleSelection: [],
				current: 1,
				total: 0,
				size: 10,
				fileList: [],
				token: '',
				fileShow: false,
				fileUrl: '',
				currentPage: 0,
				numPages: 0,
				loading: false,
				loadingText: '',
				timer: null
			}
		},
		components: {
			pdf
		},
		created() {
			this.id = this.$route.query.id;
			this.getParams();
		},
		methods: {
			// 金额变动
			applyMoneyChange(val) {
				clearTimeout(this.timer);
				this.timer = setTimeout(() => {
					// console.log(JSON.parse(JSON.stringify(val)));
					let data = {
						id: val.id,
						applyMoney: val.applyMoney
					};
					editFolderMoney(data).then(res => {
						if (res.code == 200) {
							this.$message.success('金额修改成功');
						} else {
							this.$message.error(res.msg);
							val.applyMoney = 0;
						};
					}).catch(err => {
						this.$message.error(res.msg);
					});
				}, 1000);
			},
			tableCellClick(row) {
				if (row.type == "FOLDER") {
					this.UpTarget(row);
				} else {
					this.preview(row.downloadUrl);
				};
			},
			// 预览文件
			preview(url) {
				console.log(url.split('?'));
				let type = url.split('?')[0].split('.').pop().toLowerCase();
				if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(type)) {
					this.fileType = 'image';
				} else if ('pdf' == type) {
					this.fileType = 'pdf';
				} else if (['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(type)) {
					this.fileType = 'file';
				} else {
					return false;
				};
				// console.log(type)；
				this.fileShow = true;
				this.fileUrl = url;
			},
			// 下载文件
			downloadFileFun(row) {
				if (row.type == "FOLDER") {
					this.loadingText = '正在下载文件夹';
					this.loading = true;
					downloadFile({
						folderId: row.id
					}).then(res => {
						this.loading = false;
						let blob = new Blob([res], {
							type: 'application/zip'
						});
						let url = window.URL.createObjectURL(blob);
						let a = document.createElement('a');
						a.target = '_blank';
						a.style.display = 'none';
						a.href = url;
						a.download = row.name;
						a.click();
						window.URL.revokeObjectURL(url);
					})
				} else {
					this.loadingText = '正在下载文件';
					this.loading = true;

					setTimeout(() => {
						this.loading = false;
						const x = new XMLHttpRequest();
						x.open("GET", row.downloadUrl, true);
						x.responseType = 'blob';
						x.onload = function(e) {
							const url = window.URL.createObjectURL(x.response);
							const a = document.createElement('a');
							a.href = url;
							a.target = '_blank';
							a.download = row.name;
							a.click();
							a.remove();
						};
						x.send();
					}, 1000);
				};
			},
			// 批量删除
			batchDeletion() {
				if (this.multipleSelection.length == 0) {
					this.$message({
						message: '请选择要删除的文件',
						type: 'warning'
					});
					return;
				}
				let data = {
					folderIds: [],
					fileIds: []
				};
				this.fileList.map(item => {
					this.multipleSelection.map(item2 => {
						if (item.id == item2.id) {
							if (item.type == "FOLDER") {
								data.folderIds.push(item.id);
							} else {
								data.fileIds.push(item.id);
							};
						};
					});
				});
				this.$confirm('确定删除文件?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delFolderAndFiles(data).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.getFilesFun();
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
							this.getFilesFun();
						};
					});
				});
			},
			// 删除文件
			deleteFile(row) {
				let data = {};
				if (row.type == "FOLDER") data.folderIds = [row.id];
				else data.fileIds = [row.id];
				this.$confirm(row.type == "FOLDER" ? '确定删除文件夹?' : "确定删除文件？", '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delFolderAndFiles(data).then(res => {
						if (res.code == 200) {
							this.$message({
								message: '删除成功',
								type: 'success'
							});
							this.getFilesFun();
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
							this.getFilesFun();
						};
					});
				});
			},
			// 获取文件类型
			fileType(url) {
				let type = url.split('.').pop().split('?')[0].toLowerCase();
				if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'].includes(type)) {
					return 'image';
				} else if ('pdf' == type) {
					return 'pdf';
				} else if (['doc', 'docx'].includes(type)) {
					return 'doc';
				} else if (['xls', 'xlsx'].includes(type)) {
					return 'xls';
				} else if (['ppt', 'pptx'].includes(type)) {
					return 'ppt';
				} else {
					return false;
				};
			},
			UpTarget(row) {
				// console.log(row)
				// this.$router.replace({
				// 	path: '/fileUpdataReview',
				// 	query: {
				// 		id: row.id,
				// 	}
				// })
				window.location.href = `/fileUpdataReview?id=${row.id}`;
			},
			// 新建文件夹
			newFolder(id) {
				let data = {};
				if (id) data.id = id;
				else data.parentId = this.id;
				this.$prompt('请输入文件夹名称', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /^(?!\s*$).+/,
					inputErrorMessage: '请输入文件夹名称'
				}).then(({
					value
				}) => {
					this.loading = true;
					if (id) {
						this.loadingText = '正在修改···';
					} else {
						this.loadingText = '正在创建···';
					};
					data.folderName = value;
					saveOrUpdateFolder(data).then(res => {
						if (res.code == 200) {
							this.loading = false;
							if (id) {
								this.$message({
									message: '修改成功',
									type: 'success'
								});
							} else {
								this.$message({
									message: '新建文件夹成功',
									type: 'success'
								});
							};
							this.getFilesFun();
						} else {
							this.$message({
								message: `操作失败 ${res.msg}`,
								type: 'error'
							});
						};
					});
				});
			},
			handleSizeChange(val) {
				this.size = val;
				this.getFilesFun();
			},
			handleCurrentChange(val) {
				this.current = val;
				this.getFilesFun();
			},
			// 获取文件列表
			getFilesFun() {
				getFiles({
					id: this.id,
					size: this.size,
					current: this.current
				}).then(res => {
					if (res.code == 200) {
						this.fileList = res.data.records;
						this.total = res.data.total;
					};
				});
			},
			beforeUpload(file) {
				this.loading = true;
				this.loadingText = '文件上传中···';
				const isLt300M = file.size / 1024 / 1024 < 300; // 限制小于300M
				if (!isLt300M) {
					this.$message.error('上传文件大小不能超过 300MB!');
				};
				return isLt300M;
			},
			uploadOnSuccess(res, file) {
				let data = {
					folderId: this.id,
					fileNames: [res],
					fileName: file.name,
				};
				submitFiles(data).then(res => {
					if (res.code == 200) {
						this.loading = false;
						this.$message({
							message: '上传成功',
							type: 'success'
						});
						this.throttle(this.getFilesFun(), 300);
					} else {
						this.loading = false;
						this.$message({
							message: `上传失败 ${res.msg}`,
							type: 'error'
						});
					};
				});
			},
			// 节流方法
			throttle(fn, delay) {
				let timer = null;
				return function() {
					let context = this;
					let args = arguments;
					if (!timer) {
						timer = setTimeout(function() {
							fn.apply(context, args);
							timer = null;
						}, delay);
					};
				};
			},
			// 近入页面获取必要参数
			getParams() {
				// 获取当前用户token
				this.token = localStorage.getItem('regToken') ? JSON.parse(localStorage.getItem('regToken'))
					.token : JSON
					.parse(localStorage.getItem('token')).token;
				this.matchPosition();
				this.getFilesFun();
			},
			matchPosition() {
				let data = {
					id: this.$route.query.id
				};
				getFolderById(data).then(res => {
					if (res.code == 200) {
						this.project = res.data;
					};
				});
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 批量下载
			bulkDownload() {
				if (this.multipleSelection[0]) {
					this.$message({
						message: '批量下载功能暂未开放',
						type: 'warning'
					});
				} else {
					this.$message({
						message: '请选择要下载的文件',
						type: 'warning'
					});
				};
			}
		}
	}
</script>

<style lang="scss" scoped>
	#fileUpdataReview {
		width: 100%;
		height: 100%;
		padding: 20px;

		.top {
			padding: 20px;
			margin-bottom: 20px;
			border-radius: 20px;
			box-shadow: 0 2px 18px rgba(203, 203, 203, 0.31);
			display: flex;
			align-items: center;

			.title {
				font-size: 20px;
				padding: 0 20px 0 30px;
				position: relative;

				i {
					top: 50%;
					left: 0px;
					width: 20px;
					height: 20px;
					position: absolute;
					border-radius: 50%;
					background-color: #3563E9;
					border: 5px solid #C2D0F8;
					transform: translateY(-50%);
				}
			}

			.content {
				display: flex;

				>* {
					margin-right: 20px;
				}

				.markers {
					.el-popover__reference {
						.el-icon-s-opportunity {
							color: #409EFF;
						}

						.el-icon-question {
							color: #F56C6C;
						}

						.el-icon-info {
							color: #E6A23C;
						}
					}

					.popoverCont {
						display: flex;
						flex-direction: column;

						p {
							margin-bottom: 6px;
							text-align: center;
							font-size: 14px;
							color: red;

							&:last-child {
								margin-bottom: 0px;
							}
						}
					}
				}
			}

			.tableBotions {
				padding: 0 20px;
				flex: 1;
				display: flex;
				justify-content: flex-end;

				>* {
					margin-left: 10px;
				}
			}
		}

		.option {
			margin-bottom: 0px;
			display: flex;
			align-items: center;
			padding: 0 5px;

			.el-button {
				margin-right: 10px;
			}

			.right {
				flex: 1;
				display: flex;
				justify-content: flex-end;
			}
		}

		.el-table {

			.cell {
				display: flex;
				align-items: center;
			}

			.el-button {
				padding: 0;
			}

			img {
				width: 24px;
			}
		}

		.el-pagination {
			text-align: center;
			margin-top: 20px;
		}


		::v-deep .filePreview {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0 !important;
			max-height: 95vh;
			overflow: hidden;
			overflow-y: auto;

			.el-dialog__body {
				display: flex;
				justify-content: center;

				.pdfFile,
				.otherFile {
					width: 100%;

					iframe {
						width: 100%;
						min-height: 80vh;
						border: none;
					}
				}

				.pages {
					text-align: center;
				}

				.opts {
					margin-top: 10px;
					display: flex;
					align-items: center;
					justify-content: space-around;
				}
			}
		}


	}
</style>